<template>
  <v-row align="center" justify="center" class="pa-4">
    <div class="pa-0" width="100%">
      <img
        class="mx-auto mt-n10"
        width="370"
        max-width="370"
        height="765"
        src="@/assets/12.png"
        @click="dialog = true"
      />
    </div>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay-opacity="0.9"
      max-width="375"
    >
      <a-icon
        type="close"
        class="white--text text-right mb-4"
        @click="submit"
      />
      <v-card class="pa-4 rounded-lg" height="393">
        <div
          class="text-center mt-10"
          style="font-weight: 700; font-size: 32px; color: #07204B;"
        >
          Time’s Up!
        </div>
        <div class="px-0 mt-16 mx-2">
          <img
            src="@/assets/BI.png"
            align="left"
            justify="left"
            width="54"
            height="54"
            class="mt-n3"
          />
          <span class="fn22-weight7 ml-2" style="color: #1A347F;"
            >You earn 10 points</span
          >
        </div>
        <v-row no-gutters class="mt-16">
          <v-col cols="12" class="pa-0">
            <v-btn
              outlined
              dark
              block
              x-large
              class="font-weight-bold text-capitalize rounded-lg fn16"
              style="background: linear-gradient(268.1deg, #07204B 7.54%, #1A347F 100.72%);"
              @click="submit"
            >
              Back to Games
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      dialog: false,
      id: "",
      shortDesc: "",
      desc: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  created() {
    var tipsdata = JSON.parse(Decode.decode(localStorage.getItem("tipsdata")));
    console.log("tipsdata", tipsdata);
    this.id = tipsdata.id;
    this.shortDesc = tipsdata.shortDesc;
    this.content = tipsdata.desc;
    this.showImage = tipsdata.headerImg;
    // this.picture_1 = tipsdata.headerImg;
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("Managetips");
    },
    async submit() {
      this.dialog = false;
      this.$router.push("ManageGame");
      // if (this.$refs.createForm.validate(true)) {
      //   var user = JSON.parse(
      //     Decode.decode(localStorage.getItem("userBiData"))
      //   );
      //   const auth = {
      //     headers: {
      //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
      //     },
      //   };
      //   const data = {
      //     shortDesc: this.shortDesc,
      //     // desc: this.branddesc,
      //     // activeFlag: this.activeFlag,
      //     desc: this.content,
      //     headerImg: this.pic1,
      //   };
      //   if (this.activeFlag == true) {
      //     data.activeFlag = "1";
      //   } else if (this.activeFlag == false) {
      //     data.activeFlag = "0";
      //   }
      //   const response = await this.axios.put(
      //     `${process.env.VUE_APP_API}/tips/` + this.id,
      //     data,
      //     auth
      //   );
      //   console.log("createCate", response);
      //   console.log(response.data.response_status);
      //   if (response.data.response_status == "SUCCESS") {
      //     this.$swal.fire({
      //       icon: "success",
      //       text: `สร้างเรียนรู้เพิ่มเติมสำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //     this.$router.push("Managetips");
      //   } else {
      //     this.$swal.fire({
      //       icon: "error",
      //       text: `สร้างเรียนรู้เพิ่มเติมไม่สำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //   }
      // }
    },
  },
};
</script>
